/* You can add global styles to this file, and also import other style files */

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

/* Styling mat-table */

/* Container styling */
.mat-elevation-z8 {
    margin: 20px;
    border-radius: 8px;
    overflow: hidden;
}

/* Table styling */
table {
    width: 100%;
    border-collapse: collapse;
}

/* Header row styling */
.mat-header-row {
    background-color: #98a4eb;
    color: white;
    text-align: left;
    font-weight: bold;
}

/* Header cell styling */
.mat-header-cell {
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/* Row styling */
.mat-row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/* Cell styling */
.mat-cell {
    padding: 16px;
    text-align: left;
}

/* Hover effect for rows */
.mat-row:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

/* Paginator styling */
.mat-paginator {
    background-color: white;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/* Search input styling */
mat-form-field {
    width: 100%;
    margin-bottom: 20px;
}

/* Media query for responsiveness */
@media (max-width: 600px) {

    .mat-header-cell,
    .mat-cell {
        padding: 8px;
    }

    mat-form-field {
        margin-bottom: 10px;
    }
}

/* End styling mat-table */

/* Person details */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* min-height: 100vh; */
    background-color: #f5f5f5;
    padding: 20px;
}

mat-spinner {
    margin: 20px;
}

form {
    width: 100%;
    max-width: 400px;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

mat-form-field {
    width: 100%;
    margin-bottom: 16px;
}

button {
    width: 100%;
    margin-top: 10px;
}

button+button {
    margin-top: 10px;
}